// main: ../master.scss

//ROBATO
@font-face {
    font-family: Montserrat;
    src: url('../../resources/partials/fonts/Montserrat-SemiBold.ttf');
    font-weight:300;
    font-style: normal;
}

//LATO
@font-face {
    font-family: Lato;
    src: url('../../resources/partials/fonts/Lato-Light.ttf');
    font-weight:300;
    font-style: normal;
}
@font-face {
    font-family: Lato;
    src: url('../../resources/partials/fonts/Lato-Medium.ttf');
    font-weight:500;
    font-style: normal;
}

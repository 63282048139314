@import "../DarwinReactLibrary/main";
@import "./partials/_fonts";

//Black and White
$darkDarkColor: #0D0D0D;
$darkColor: #262626;
$darkColor2: #434343;

$grayColor1: #8C8C8C;
$grayColor2: #BFBFBF;
$grayColor3: #DADADA;

$offWhiteColor2: #F4F4F4;
$offWhiteColor: #FAFCFD;
$whiteColor: #FFFFFF;

//Colors
$redColor: #FF2C00;
$linkRedColor: #C6756F;
$blueColor: #0099FF;
$greenColor: #30E2B1;

//Dimensions
$breakSmall: 650px; //size at which the top menu is hidden to show the collapsed menu button.
$breakSmallHeight: 650px; //size at which the content shrinks vertically.

@mixin whiteBackground() {
  background: $whiteColor;
  background-image: radial-gradient(closest-side at 50% 50%, $whiteColor 50%, $offWhiteColor 80%);
}

//Common Mixins
@mixin bodyFont() {
  font-family: Lato, Helvetica, Arial, sans-serif;
}

@mixin titleFont() {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  letter-spacing: 3px;
  text-transform: uppercase;
}

@mixin fontSizeTitle() {
  font-size: 36px;
  font-weight: 500;
}
@mixin fontSizeStat() {
  font-size: 24px;
  font-weight: 500;
}
@mixin fontSizeMedium() {
  font-size: 18px;
  font-weight: 300;
}
@mixin fontSizeMid() {
  font-size: 16px;
  font-weight: 500;
}
@mixin fontSizeNormal() {
  font-size: 14px;
  font-weight: 300;
}
@mixin fontSizeSmall() {
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
}
@mixin fontSizeTiny() {
  font-size: 10px;
  font-weight: 500;
}

@mixin ellipsis() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin boxShadow() {
  box-shadow: 2px 2px 2px 0px transparentize($darkColor, 0.80);
  //box-shadow: 0 1px 3px transparentize($darkColor, 0.80), 0 1px 2px transparentize($darkColor, 0.80);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);

  &:hover {
    box-shadow: 2px 2px 8px 0px transparentize($darkColor, 0.80);
    //box-shadow: 0 4px 12px transparentize($darkColor, 0.80), 0 4px 4px transparentize($darkColor, 0.80);
  }
}

//Base Properties
html {
  background: $offWhiteColor;
}
body {
  background: $offWhiteColor;
  @include bodyFont();
  font-weight: 300;
  font-size: 14px;
  color: $darkColor;
}
.App {
  @include whiteBackground();
  position: relative;
  height: 100%;
  width: 100%;
}
.TopBar {
  padding: 13px 27px;
  display: flex;
  flex-flow: row nowrap;

  .TopBarLogo {
    @include pushable();
    height: 46px;
    margin-right: 15px;
  }

  .TopBarMenu {
    display: none;
    height: 45px;
    margin-top: 0px;
    @include pushable();
    padding: 14px 0px;
  }
  .TopBarItem {
    @include pushable();
    padding: 0px 5px;
    margin: 0px 10px;
    text-align: center;
    .TopBarItemTitle {
      padding-top: 15px;
      @include titleFont();
      font-size: 12px;
      min-width: 46px;
    }
    .TopBarItemImage {
      padding-top: 8px;
      height: 48px;
    }
  }
  .TopBarItemTitleHighlight {
    color: $blueColor;
  }
  .TopBarSpacing {
    flex-grow: 1;
  }
}
.BottomCorner {
  z-index: 1000;
  position: absolute;
  right: 0px;
  bottom: 5px;
  @include pushable();
}

//Full Screen Menu
.OverlayMenu {
  z-index: 200;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  @include whiteBackground();
  opacity: 1;
  transition: all 500ms;
  text-align: center;
  overflow: hidden;

  display: flex;
  flex-flow: column nowrap;

  .TopBarItemGroup {
    .TopBarItem {
      display: inline-block;
      @include pushable();
      padding: 0px 5px;
      margin: 15px 10px;
      text-align: center;
      .TopBarItemTitle {
        padding-top: 15px;
        @include titleFont();
        font-size: 12px;
      }
      .TopBarItemImage {
        padding-top: 8px;
      }
    }
  }

  .OverlayMenuLogo {
    @include pushable();
    height: 46px;
    margin: 13px 0px;
  }
  hr {
    height: 1px;
    margin: 0px;
    border-top: 1px solid #DADADA;
  }
  .OverlayMenuSpacer {
    flex-grow: 1;
  }
  .OverlayMenuFooter {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;

    padding: 20px;

    .OverlayMenuFooterGroup {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
    }
    .OverlayMenuFooterItem {
      text-align: center;
      @include fontSizeNormal();
      font-weight: 400;
      color: $grayColor2;
      padding: 5px 10px;
    }
    .OverlayMenuFooterItemFirst {
      flex-grow: 1;
    }
    .OverlayMenuFooterItemLink {
      color: $grayColor1;
      @include pushable();

      &:hover {
        color: $grayColor2;
      }
    }
  }
  .OverlayMenuX {
    position: absolute;
    top: 16px;
    right: 20px;
    width: 40px;
    height: 40px;
    padding: 5px;
    @include pushable();
  }
}
.OverlayMenuHidden {
  opacity: 0.5;
  height: 0px;
}

//Content Views
.ContentView {
  width: 100%;
  height: 100%;
  text-align: center;
  min-height: $breakSmallHeight;
}

.Footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: flex-start;
  text-align: left;
  max-width: 1200px;
  margin: 0px auto;
  @include fontSizeNormal();
  color: $grayColor1;
  padding: 40px 0px;

  .FooterGroupLogo {
    padding: 20px 20px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;

    img {
      height: 46px;
    }
    .FooterText {
    }
  }
  .FooterGroup {
    padding: 20px 20px;
    .FooterGroupHeader {
      color: $darkColor;
      font-weight: 600;
    }
    .FooterGroupLink {
      padding-top: 8px;
      @include pushable();
      transition: all 0.5s;

      &:hover {
        color: $blueColor;
      }
    }
  }
}

.HomeView {
  display: flex;
  min-height: calc(100% - 106px);
  width: calc(100%);
  padding: 0px 20px;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  .HomeViewLogo {
    max-width: 100%;
    width: 375px;
    height: 254px;
    padding-right: 21px;
  }
  .HomeViewDescription {
    @include bodyFont();
    font-size: 20px;
    margin-top: 8px;
    color: $darkColor;
  }
}
.AppView {
  display: flex;
  min-height: calc(100% - 106px);
  width: calc(100%);
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  .AppViewTop {
    padding: 0px 20px;
    min-height: 400px;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    .AppViewText1 {
      @include bodyFont();
      font-size: 20px;
      color: $darkColor2;
    }
    .AppViewText2 {
      @include bodyFont();
      font-weight: 500;
      font-size: 20px;
      color: $darkColor2;
      padding: 10px 0px;
    }
    .AppViewInputResult {
      height: 72px;
      line-height: 72px;
      @include bodyFont();
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 1px;
      color: $blueColor;
    }
    .AppViewInputError {
      height: 72px;
      line-height: 72px;
      @include bodyFont();
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 1px;
      color: $redColor;
    }
  }
  .AppViewBottom {
    height: 180px;
    position: relative;
    .AppViewBottomImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
    }
    .AppViewBottomImageCover {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;

      background: rgb(255,255,255);
      background: -moz-linear-gradient(0deg, rgba(255,255,255,0.5) 0%, rgba(250,252,253,1) 95%);
      background: -webkit-linear-gradient(0deg, rgba(255,255,255,0.5) 0%, rgba(250,252,253,1) 95%);
      background: linear-gradient(0deg, rgba(255,255,255,0.5) 0%, rgba(250,252,253,1) 95%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    }
  }
}
.ContactView {
  display: flex;
  min-height: calc(100% - 106px);
  width: calc(100%);
  padding: 0px 20px;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  .ContactViewTitle {
    @include titleFont();
    font-size: 36px;
    color: $darkColor;
  }
  .ContactViewDescription {
    @include bodyFont();
    font-weight: 300;
    font-size: 20px;
    margin-top: 15px;
    color: $darkColor2;
    max-width: 580px;
  }
  .ContactViewEmail {
    @include bodyFont();
    font-weight: 500;
    font-size: 20px;
    margin-top: 15px;
    color: $blueColor;
    text-decoration: underline;
    @include pushable();
    width: 100%;
    max-width: 580px;
    padding-bottom: 15px;
    //border-bottom: 1px solid $grayColor3;
  }
  .ContactViewLink {
    @include bodyFont();
    font-weight: 500;
    font-size: 20px;
    margin-top: 15px;
    color: $grayColor1;
    @include pushable();

    &:hover {
      color: $grayColor2;
    }
  }
}
.Four0FourView {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100% - 106px);
  width: calc(100%);
  max-width: 800px;
  margin: 0px auto;
  padding: 0px 20px;
  text-align: center;

  .Four0FourViewImage {
    max-height: 300px;
    max-width: 100%;
  }
  .Four0FourViewTitle {
    text-align: center;
    @include titleFont();
    font-size: 36px;
    color: $darkColor;
  }
  .Four0FourViewDescription {
    text-align: center;
    @include bodyFont();
    font-weight: 300;
    font-size: 20px;
    margin-top: 15px;
    color: $darkColor2;
  }
}
.AboutView {
  display: block;
  min-height: calc(100% - 106px);
  width: calc(100%);
  max-width: 800px;
  margin: 0px auto;
  padding: 0px 20px;
  text-align: center;

  .AboutViewImage {
    max-height: 300px;
    max-width: 100%;
  }
  .AboutViewTitle {
    text-align: left;
    @include titleFont();
    font-size: 36px;
    color: $darkColor;
  }
  .AboutViewDescription {
    text-align: left;
    @include bodyFont();
    font-weight: 300;
    font-size: 20px;
    margin-top: 15px;
    color: $darkColor2;
  }
  .AboutViewIFrame {
    width: 100%;
    max-width: 414px;
    min-width: 320px;
    height: 600px;
    margin-top: 32px;
    border: 1px solid $offWhiteColor2;
  }
}
.SitemapView {
  display: block;
  min-height: calc(100% - 106px);
  width: calc(100%);
  max-width: 1200px;
  margin: 0px auto;
  padding: 0px 20px;
  text-align: center;

  .SitemapViewTitle {
    text-align: left;
    @include titleFont();
    font-size: 36px;
    color: $darkColor;
    padding: 20px 0px;
    border-bottom: 1px solid $grayColor3;
    margin-bottom: 20px;
  }
  .SitemapViewGroups {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;

    .SitemapViewGroup {
      padding: 0px 50px 50px 0px;
      .SitemapViewHeader {
        text-align: left;
        @include bodyFont();
        font-weight: 600;
        font-size: 20px;
        margin-top: 15px;
        color: $darkColor2;
      }
      .SitemapViewLink {
        text-align: left;
        @include bodyFont();
        font-weight: 300;
        font-size: 20px;
        margin-top: 15px;
        color: $darkColor2;
        @include pushable();

        &:hover {
          color: $blueColor;
        }
      }
    }
  }
}
.WorksView {
  min-height: calc(100% - 72px);
  width: 100%;

  .WorksViewCategories {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    .WorksViewCategory {
      @include titleFont();
      font-size: 24px;
      margin: 15px;
      width: 180px;
      @include pushable();
    }
    .WorksViewCategoryActive {
      color: $blueColor;
    }
    .WorksViewCategorySpace {
      width: 0px;
      margin: 0px;
      transition: all 500ms;
    }
    .WorksViewCategorySpaceActive {
      width: 180px;
      margin: 15px;
    }
  }
  .WorksViewCardContainer {
    padding-top: 40px;
    padding-bottom: 15px;
    padding-left: calc(50% - 137px);
    padding-right: calc(50% - 137px);
    overflow-x: scroll;
    white-space: nowrap;

    .WorksViewCard {
      @include pushable();
      display: inline-block;
      margin: 0px 17px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
      border-radius: 30px;
      position: relative;

      .WorksViewCardBackground {
        top: 0px;
        left: 0px;
        height: 355px;
        width: 240px;
      }
      .WorksViewCardBox {
        position: absolute;
        bottom: 25px;
        left: 0px;
        padding: 0px 22px;
        height: 44px;
        background: $whiteColor;
        border-radius: 0px 22px 22px 0px;
        box-shadow: 0 5x 10px 0 rgba(0, 0, 0, 0.15);

        .WorksViewCardBoxLabel {
          @include bodyFont();
          font-weight: 500;
          font-size: 18px;
          line-height: 44px;
          color: $darkColor;
        }
      }
    }
    .WorksViewCardActive {
      box-shadow: 0px 2px 4px 0px;
    }
  }
  .WorksViewBottom {
    .WorksViewBottomTitle {
      @include titleFont();
      font-size: 18px;
      color: $darkColor;
      padding-bottom: 10px;
    }
    .WorksViewBottomDescription {
      @include bodyFont();
      font-size: 12px;
      font-weight: 500;
      color: $darkColor2;
      line-height: 16px;
    }
  }
}
.WorkDetailsView {
  display: flex;
  flex-flow: row wrap;
  height: calc(100% - 106px);
  min-height: calc(100% - 106px);
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: scroll;

  .WorkDetailsViewLeft {
    flex-basis: 50%;
    min-height: 100%;
    position: relative;
    text-align: left;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px 0px 32px 0px;

    .WorkDetailsViewLeftCategory {
      display: inline-block;
      height: 44px;
      background: $offWhiteColor2;
      line-height: 44px;
      @include bodyFont();
      font-weight: 500;
      color: $darkColor;
      font-size: 18px;
      text-transform: uppercase;
      padding: 0px 28px 0px 36px;
      border-radius: 0px 22px 22px 0px;
      margin: 40px 0px 80px 0px;
    }
    .WorkDetailsViewLeftTitle {
      @include titleFont();
      text-transform: none;
      letter-spacing: normal;
      font-size: 36px;
      color: $darkColor;
      padding: 0px 36px;
      margin-bottom: 30px;
    }
    .WorkDetailsViewLeftDescription {
      padding: 0px 36px;
      margin: 10px 0px;
      @include bodyFont();
      font-size: 18px;
      color: $darkColor2;
    }
    .WorkDetailsViewLeftActions {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      padding: 0px 36px;
      margin-top: 60px;

      .WorkDetailsViewLeftAction {
        margin: 0px 4px 4px 0px;
        @include pushable();

        .WorkDetailsViewLeftActionImage {
          height: 40px;
        }
      }
    }
  }
  .WorkDetailsViewLeftFull {
    flex-basis: 100%;
  }
  .WorkDetailsViewRight {
    flex-basis: 50%;
    min-height: 100%;
    border-radius: 30px 0px 0px 0px;
    overflow: hidden;
    position: relative;

    .WorkDetailsViewRightBackground {
      position: absolute;
      z-index: 100;
      left: 0px;
      top: 0px;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: 0px 0px;
    }
    .WorkDetailsViewRightNext {
      right: 0px;
      bottom: 100px;
    }
  }
  .WorkDetailsViewRightNext {
    position: absolute;
    z-index: 101;
    right: 0px;
    bottom: 100px;
    background: $whiteColor;
    color: $darkColor;
    padding: 11px 22px 11px 42px;
    border-radius: 22px 0px 0px 22px;
    @include bodyFont();
    font-weight: 500;
    font-size: 18px;
    @include pushable();
    @include noselect();
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  }
}
.LegalView {
  min-height: calc(100% - 106px);
  width: calc(100%);

  .LegalDocument {
    text-align: left;
    padding: 50px 27px;
    .LegalTitle {
      @include fontSizeTitle();
      color: $darkColor;
    }
    .LegalDate {
      color: $grayColor1;
      @include fontSizeTiny();
      padding-bottom: 20px;
    }
    .LegalSection {
      color: $darkColor;
      @include fontSizeMedium();
      font-weight: 600;
      padding-top: 20px;
    }
    .LegalSectionLarge {
      color: $darkColor;
      @include fontSizeStat();
      font-weight: 600;
      padding-top: 20px;
    }
    .LegalBody {
      color: $darkColor;
      @include fontSizeNormal();
    }
  }
}
.GeojiView {
  display: block;
  min-height: calc(100% - 106px);
  width: calc(100%);
  max-width: 800px;
  margin: 0px auto;
  padding: 0px;
  text-align: center;

  .GeojiViewIFrame {
    width: 100%;
    max-width: 414px;
    min-width: 320px;
    height: 600px;
    margin-top: 32px;
    border: 1px solid $offWhiteColor2;
  }
}

//Small Screen
@media (max-width: $breakSmall) {
  .HomeView, .AppView, .ContactView, .WorksView, .WorkDetailsView, .LegalView {
    min-height: calc(100% - 72px);
  }

  .TopBar {
    .TopBarItem {
      display: none;
    }
    .TopBarMenu {
      display: block;
    }
  }
  .BottomCorner {
    display: none;
  }
  .WorksView {
    .WorksViewCategories {
      .WorksViewCategory {
        margin: 0px 5px;
        flex-basis: 100%;
      }
      .WorksViewCategorySpace {
        width: 0px;
        margin: 0px;
      }
      .WorksViewCategorySpaceActive {
        width: 0px;
        margin: 0px;
      }
    }
  }
  .WorkDetailsView {
    .WorkDetailsViewLeft {
      flex-basis: 100%;
    }
    .WorkDetailsViewRight {
      flex-basis: 100%;
    }
  }
}
@media (max-height: $breakSmallHeight) {
  .WorksView {
    .WorksViewCardContainer {
      .WorksViewCard {
        margin: 0px 56px;
        .WorksViewCardBackground {
          height: 240px;
          width: 162px;
        }
        .WorksViewCardBox {
          height: 36px;

          .WorksViewCardBoxLabel {
            font-size: 12px;
            line-height: 36px;
          }
        }
      }
    }
  }
  .WorkDetailsView {
    .WorkDetailsViewLeft {
      flex-basis: 100%;
    }
    .WorkDetailsViewRight {
      flex-basis: 100%;
    }
  }
}

//Inputs
.ButtonDiv {
  margin: 10px auto 0px auto;
}
.ForgotPasswordDiv {
  margin: 10px auto 0px auto;
}
.InputDiv {
  padding: 10px 0px 0px 0px;
}
.InputDivHideInvalid {
  .inputBottomLineLightContainer {
    .inputBottomLineInvalid {
      min-height: 0px;
    }
  }
}
.InputExpander {
  text-align: left;
  max-width: 420px;
  margin: 0px auto 20px auto;
  cursor: pointer;
  @include fontSizeNormal();
  font-weight: 400;
}
.InputBreakDiv {
  padding: 0px 0px 0px 0px;
}
.InputSection {
  margin: 0px auto;
  padding: 20px 0px 0px;
  @include fontSizeStat();
  color: $darkColor;
  text-align: left;
  width: 480px;

  @media (max-width: 480px) {
    width: 280px;
  }
}
.SettingsToggle {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px;

  .SettingsToggleName {
    padding-left: 15px;
    @include fontSizeNormal();
    color: $grayColor2;
  }
}
.inputBottomLineLightContainer {
  width: 420px;
  text-align: center;
  display: inline-block;

  @media (max-width: 460px) {
    width: 280px;
  }

  .inputBottomLineTitle {
    @include fontSizeSmall();
    text-transform: none;
    text-align: left;
    padding: 0px 12px 0px 0px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
  .inputBottomLineDescription {
    @include fontSizeNormal();
    text-align: left;
    padding: 0px 12px 4px 12px;
  }
  .inputBottomLineNote {
    @include fontSizeTiny();
    font-weight: 300;
    color: $grayColor2;
    text-align: left;
    padding: 10px 12px 0px 0px;
  }
  .inputBottomLineFee {
    @include fontSizeTiny();
    font-weight: 400;
    color: $grayColor2;
    text-align: left;
    padding: 10px 12px 0px 0px;
  }

  .inputBottomLineInvalid {
    @include fontSizeSmall();
    text-transform: none;
    min-height: 17px;
    visibility: hidden;
    color: $redColor;
    text-align: right;
    padding: 0px 12px 0px 12px;
    margin-top: 5px;
  }

  .inputBottomLineLightFlexBox {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    padding: 0px;
    margin: 0px;
    @include fontSizeMedium();
    background-color: $offWhiteColor2;
    background-image: none;
    text-align: left;
    border-radius: 8px;
    box-shadow: none;
    border: 0px;
    color: $darkColor;

    .inputBottomLineIcon {
      height: 20px;
      padding: 0px 12.5px 0px 0px;
      transition: all 500ms ease-in-out;
    }
    .inputBottomLineIconHidden {
      opacity: 0.0;
      width: 0px;
      padding: 0px;
    }

    .manualSelect {
      width: 100%;
      height: 45px;
      -webkit-appearance: none;
      outline: none;
      box-shadow: none;
      border: 0px;
      background-color: $offWhiteColor2;
      background-image: none;
      margin: 0px;
      padding: 0px 12.5px;
      color: $darkColor;
    }
  }
  .inputBottomLineLightFlexBoxClear {
    background: transparent;

    .inputBottomLineIcon {
      padding-left: 10px;
    }
    .inputBottomLineIconHidden {
      padding: 0px;
    }
  }
  .inputBottomLineMobileDate {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 45px;
    padding: 0px;
    margin: 0px;
    @include fontSizeMedium();
    background-color: $offWhiteColor2;
    background-image: none;
    text-align: left;
    border-radius: 8px;
    box-shadow: none;
    border: 0px;
    color: $darkColor;

    input {
      vertical-align: middle;
      -webkit-appearance: none;
      -moz-appearance: textfield;
    }

    .inputBottomLineIcon {
      height: 20px;
      padding: 0px 12.5px 0px 0px;
      transition: all 500ms ease-in-out;
    }
    .inputBottomLineIconHidden {
      opacity: 0.0;
      width: 0px;
      padding: 0px;
    }
  }
  .GoogleMapsDropdownContainer {
    margin: 5px;
    @include fontSizeNormal();
    color: $grayColor2;

    .GoogleMapsDropdownContainerLoading {
      display: none;
    }
    .GoogleMapsItem, .GoogleMapsItemActive {
      @include pushable();
      padding: 5px;
      border-radius: 8px;
    }
    .GoogleMapsItemActive {
      background: $offWhiteColor2;
    }
  }

  .inputBottomLineLight {
    display: inline-block;
    width: 100%;
    height: 45px;
    padding: 9px 12.5px 9px 12.5px;
    margin: 0px;
    @include fontSizeMedium();
    background-color: $offWhiteColor2;
    background-image: none;
    text-align: left;
    border-radius: 8px;
    box-shadow: none;
    border: 0px;
    color: $darkColor;

    &:focus {
      outline: 0px !important;
    }
  }
  .inputBottomLineBlockLight {
  	height: 1px;
  	width:calc(100% - 24px);
  	margin:0px 0px 0px 12px;
  	background-color: $darkColor;
  	transition: background-color 0.5s ease, width 0.5s ease;
  }
  .inputBottomLineLight:focus ~ .inputBottomLineBlockLight {
  	background-color: $grayColor2;
  	width:calc(100% - 24px);
  }

  .inputBottomLineCheckbox {
    display: flex;
    flex-flow: row nowrap;
    padding: 4px 12px 0px 12px;
    justify-content: flex-start;
    align-items: stretch;

    //this is the thing we are clicking
    label {
      width: 22px;
      height: 22px;
      border-radius: 4px;
      @include pushable();
      transition: all 300ms;

      .dualImageSingle {
        margin: 0px;
        vertical-align: middle;
        width: 14px;
      }
    }
    .inputBottomLineCheckboxTrue {
      background: $darkColor2;
    }
    .inputBottomLineCheckboxFalse {
      background: $offWhiteColor;
    }
    span {
      @include fontSizeMid();
      color: $offWhiteColor;
      padding: 0px 0px 0px 16px;
    }
    //hide the input
    input {
      border: 0;
      clip: rect(0 0 0 0);
      clippath: inset(50%);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
  }
  .inputBottomLineCheckboxLight {
    .inputBottomLineCheckboxTrue {
      background: $darkColor2;
    }
    .inputBottomLineCheckboxFalse {
      background: $whiteColor;
      border: 1px solid $grayColor2;
    }
    span {
      color: $grayColor2;
    }
  }

  .inputBottomLineUnderline {
    width: 20px;
    height: 2px;
    background: $grayColor2;
    border-radius: 2px;
    margin: 0px auto;
    transition: all 250ms;
  }
  .inputBottomLineUnderlineFull {
    width: 100%;
    background: $blueColor;
  }
}


.inputBottomLineLightContainerInvalid {

  .inputBottomLineInvalid {
    visibility: visible;
  }

  .inputBottomLineLight {
    color: $redColor;
  }
  .inputBottomLineBlockLight {
    //background-color: $redColor;
  }
  .inputBottomLineLight:focus ~ .inputBottomLineBlockLight {
  	background-color: $redColor;
  }
}
.inputBottomLineLightContainerValid {

  .inputBottomLineLight {
    color: $darkColor;
  }
  .inputBottomLineBlockLight {
    //background-color: $redColor;
  }
  .inputBottomLineLight:focus ~ .inputBottomLineBlockLight {
  	background-color: $darkColor;
  }
}

//Additions to Input Styles
.inputStyleLight {
  .inputBottomLineLightFlexBox {
    background-color: transparent;
    .inputBottomLineLight {
      background-color: transparent;
      text-align: center;
      color: $blueColor;
      @include bodyFont();
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }
  .inputBottomLineInvalid {
    text-align: center;
    @include bodyFont();
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    min-height: 20px;
  }

  //placeholder color
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $grayColor2;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $grayColor2;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $grayColor2;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $grayColor2;
  }
}
.inputStyleLight.inputBottomLineLightContainerInvalid {
  .inputBottomLineLight {
    color: $redColor;
  }
  .inputBottomLineUnderlineFull {
    background: $redColor;
  }
}

// Button Styles
.MaterialButton {
  color: $whiteColor;
  @include fontSizeMedium();
  padding: 7px 20px;
  border-radius: 9px;
  min-width: 260px;
  background-color: $whiteColor;
  @include pushable();
  transition: all 0.5s;
  //transition: background-color 0.5s, box-shadow 0.5s, color 0.5s, transform 0.5s;
  vertical-align: top;
  text-align: center;

  &:hover, &:active, &:focus {
    color: $whiteColor;
    background-color: lighten($darkColor, 10%);
  }

  img {
    height: 18px;
    vertical-align: middle;
    margin-right: 18px;
  }
  span {
    vertical-align: middle;
  }
}
.MaterialButtonFull {
  width: 100%;
}
.MaterialButtonLarge {
  min-width: 220px;
}

.MaterialButtonLarge {
  width: 420px;

  @media (max-width: 460px) {
    width: 280px;
  }
}
.MaterialButtonSmall {
  min-width: 275px;
  margin: 4px;
}
.MaterialButtonContent {
  min-width: 50px;
  margin: 4px;
}
.MaterialButtonTiny {
  min-width: 50px;
  margin: 4px;
  @include fontSizeTiny();
}

.MaterialButtonDark {
  //background-color: $greenColor;
  color: $offWhiteColor;
  @include boxShadow();
  border: 0px solid $greenColor;
  background-color: $greenColor;
  //@include greenGradient();
  padding: 9px 20px;

  &:hover, &:active, &:focus {
    background-color: $greenColor;
    color: $offWhiteColor;
    border: 0px solid $whiteColor;
  }
}
.MaterialButtonDarkText {
  background-color: $darkColor;
  color: $offWhiteColor;

  &:hover, &:active, &:focus {
    background-color: $darkColor;
    color: $grayColor2;
  }
}
.MaterialButtonDarkActive {
  background-color: $offWhiteColor;
  color: $darkColor;
  border: 0px solid $offWhiteColor;
  padding: 9px 20px;

  &:hover, &:active, &:focus {
    background-color: $offWhiteColor;
    color: $darkColor;
  }
}
.MaterialButtonLight {
  background-color: $whiteColor;
  color: $grayColor2;
  @include boxShadow();
  //border: 2px solid $grayColor2;

  &:hover, &:active, &:focus {
    background-color: $whiteColor;
    color: $grayColor2;
  }
}
.MaterialButtonLightText {
  background-color: $offWhiteColor;
  color: $grayColor2;

  &:hover, &:active, &:focus {
    background-color: $offWhiteColor;
    color: $grayColor2;
  }
}

// Input placeholder text
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $grayColor1;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $grayColor1;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $grayColor1;
}
:-moz-placeholder { /* Firefox 18- */
  color: $grayColor1;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none !important;
}

//Now import the partials, must be after the variables and mixins so they can be used within the partials.
@import "./partials/_components.scss";
@import "./partials/_fonts.scss";
@import "./partials/_home.scss";

//Fades the element in on load
@mixin fadeIn() {
	-webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
	-ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
//Fades the element and slides it up on load
@mixin slideUp() {
	-webkit-animation: slideUp 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: slideUp 0.5s; /* Firefox < 16 */
	-ms-animation: slideUp 0.5s; /* Internet Explorer */
  -o-animation: slideUp 0.5s; /* Opera < 12.1 */
  animation: slideUp 0.5s;
}
@keyframes slideUp {
	from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0px); }
}
/* Firefox < 16 */
@-moz-keyframes slideUp {
	from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0px); }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes slideUp {
	from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0px); }
}
/* Internet Explorer */
@-ms-keyframes slideUp {
	from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0px); }
}
/* Opera < 12.1 */
@-o-keyframes slideUp {
	from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0px); }
}
.AnimationSlideUp {
	@include slideUp();
}

//Fades the element and slides it right on load
@mixin slideRight($time: 0.5s) {
	-webkit-animation: slideRight $time; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: slideRight $time; /* Firefox < 16 */
	-ms-animation: slideRight $time; /* Internet Explorer */
  -o-animation: slideRight $time; /* Opera < 12.1 */
  animation: slideRight $time;
}
@keyframes slideRight {
	from { opacity: 0; transform: translateX(-50px); }
  to { opacity: 1; transform: translateY(0px); }
}
/* Firefox < 16 */
@-moz-keyframes slideRight {
	from { opacity: 0; transform: translateX(-50px); }
  to { opacity: 1; transform: translateY(0px); }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes slideRight {
	from { opacity: 0; transform: translateX(-50px); }
  to { opacity: 1; transform: translateY(0px); }
}
/* Internet Explorer */
@-ms-keyframes slideRight {
	from { opacity: 0; transform: translateX(-50px); }
  to { opacity: 1; transform: translateY(0px); }
}
/* Opera < 12.1 */
@-o-keyframes slideRight {
	from { opacity: 0; transform: translateX(-50px); }
  to { opacity: 1; transform: translateY(0px); }
}
.AnimationSlideRight {
	@include slideRight();
}

//Fades the element and slides it right on load
@mixin slideLeft($time: 1.0s) {
	-webkit-animation: slideLeft $time; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: slideLeft $time; /* Firefox < 16 */
	-ms-animation: slideLeft $time; /* Internet Explorer */
  -o-animation: slideLeft $time; /* Opera < 12.1 */
  animation: slideLeft $time;
}
@keyframes slideLeft {
	from { opacity: 0; transform: translateX(50px); }
  to { opacity: 1; transform: translateY(0px); }
}
/* Firefox < 16 */
@-moz-keyframes slideLeft {
	from { opacity: 0; transform: translateX(50px); }
  to { opacity: 1; transform: translateY(0px); }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes slideLeft {
	from { opacity: 0; transform: translateX(50px); }
  to { opacity: 1; transform: translateY(0px); }
}
/* Internet Explorer */
@-ms-keyframes slideLeft {
	from { opacity: 0; transform: translateX(50px); }
  to { opacity: 1; transform: translateY(0px); }
}
/* Opera < 12.1 */
@-o-keyframes slideLeft {
	from { opacity: 0; transform: translateX(50px); }
  to { opacity: 1; transform: translateY(0px); }
}
.AnimationSlideLeft {
	@include slideLeft();
}

//A SHAKE ANIMATION
@mixin shake() {
	-webkit-animation: shake 1s !important; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: shake 1s !important; /* Firefox < 16 */
	-ms-animation: shake 1s !important; /* Internet Explorer */
  -o-animation: shake 1s !important; /* Opera < 12.1 */
  animation: shake 1s !important;
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
.AnimationShake {
	@include shake();
}

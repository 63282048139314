/* IMPORT FILES */
@import "../DarwinReactLibrary/bootstrap/dist/css/bootstrap.min.css";
@import "../DarwinReactLibrary/_animations";

$grayBackgroundTextColor: #888B8C;

/// Creates a material shadow
@mixin materialSmallShadow() {
  //use this to show a little separation between components
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}
@mixin materialThickShadow() {
  //use this to show a little separation between components
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.15);
}
@mixin materialSpreadShadow() {
  //use this for highlighting a button or element on the screen
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
}
/// Creates a card shadow
@mixin cardShadow($radius) {
	box-shadow: 0 $radius $radius * 2 0 rgba(0, 0, 0, 0.2), 0 $radius * 1.5 $radius * 5 0 rgba(0, 0, 0, 0.19);
}
@mixin lightCardShadow($radius) {
	box-shadow: 0 $radius $radius * 2 0 rgba(0, 0, 0, 0.1), 0 $radius * 1.5 $radius * 5 0 rgba(0, 0, 0, 0.09);
}
/// Creates an inner shadow
@mixin innerShadow($radius) {
	box-shadow: inset 0 $radius $radius * 2 0 rgba(0, 0, 0, 0.2), inset 0 $radius * 1.5 $radius * 5 0 rgba(0, 0, 0, 0.19);
}
@mixin lightInnerShadow($radius) {
	box-shadow: inset 0 $radius $radius * 2 0 rgba(0, 0, 0, 0.1), inset 0 $radius * 1.5 $radius * 5 0 rgba(0, 0, 0, 0.09);
}
///Makes the button shrink and expand when clicking it
@mixin pushable() {
	transition: transform 0.5s;
	cursor: pointer;

	&:active {
		transform: scale(0.9);
	}
}
@mixin noselect() {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* GENERAL FORMATTING */
html {
	height:100%;
}
body {
	height:100%;
}
// A basic links
a {
  &:hover {
		cursor: pointer;
  }
}
//button focusing
.btn:focus, .btn:active {
  color: inherit;
	outline: none !important;
}
.btn:focus {
  color: inherit;
	box-shadow: none;
}
//input placeholder text
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $grayBackgroundTextColor;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $grayBackgroundTextColor;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $grayBackgroundTextColor;
}
:-moz-placeholder { /* Firefox 18- */
  color: $grayBackgroundTextColor;
}
